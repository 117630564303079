import _reactReconcilerProductionMin from "./cjs/react-reconciler.production.min.js";
export { _reactReconcilerProductionMin as default };
export const IsThisRendererActing = _reactReconcilerProductionMin.IsThisRendererActing,
      act = _reactReconcilerProductionMin.act,
      attemptContinuousHydration = _reactReconcilerProductionMin.attemptContinuousHydration,
      attemptHydrationAtCurrentPriority = _reactReconcilerProductionMin.attemptHydrationAtCurrentPriority,
      attemptSynchronousHydration = _reactReconcilerProductionMin.attemptSynchronousHydration,
      attemptUserBlockingHydration = _reactReconcilerProductionMin.attemptUserBlockingHydration,
      batchedEventUpdates = _reactReconcilerProductionMin.batchedEventUpdates,
      batchedUpdates = _reactReconcilerProductionMin.batchedUpdates,
      createComponentSelector = _reactReconcilerProductionMin.createComponentSelector,
      createContainer = _reactReconcilerProductionMin.createContainer,
      createHasPsuedoClassSelector = _reactReconcilerProductionMin.createHasPsuedoClassSelector,
      createPortal = _reactReconcilerProductionMin.createPortal,
      createRoleSelector = _reactReconcilerProductionMin.createRoleSelector,
      createTestNameSelector = _reactReconcilerProductionMin.createTestNameSelector,
      createTextSelector = _reactReconcilerProductionMin.createTextSelector,
      deferredUpdates = _reactReconcilerProductionMin.deferredUpdates,
      discreteUpdates = _reactReconcilerProductionMin.discreteUpdates,
      findAllNodes = _reactReconcilerProductionMin.findAllNodes,
      findBoundingRects = _reactReconcilerProductionMin.findBoundingRects,
      findHostInstance = _reactReconcilerProductionMin.findHostInstance,
      findHostInstanceWithNoPortals = _reactReconcilerProductionMin.findHostInstanceWithNoPortals,
      findHostInstanceWithWarning = _reactReconcilerProductionMin.findHostInstanceWithWarning,
      flushControlled = _reactReconcilerProductionMin.flushControlled,
      flushDiscreteUpdates = _reactReconcilerProductionMin.flushDiscreteUpdates,
      flushPassiveEffects = _reactReconcilerProductionMin.flushPassiveEffects,
      flushSync = _reactReconcilerProductionMin.flushSync,
      focusWithin = _reactReconcilerProductionMin.focusWithin,
      getCurrentUpdateLanePriority = _reactReconcilerProductionMin.getCurrentUpdateLanePriority,
      getFindAllNodesFailureDescription = _reactReconcilerProductionMin.getFindAllNodesFailureDescription,
      getPublicRootInstance = _reactReconcilerProductionMin.getPublicRootInstance,
      injectIntoDevTools = _reactReconcilerProductionMin.injectIntoDevTools,
      observeVisibleRects = _reactReconcilerProductionMin.observeVisibleRects,
      registerMutableSourceForHydration = _reactReconcilerProductionMin.registerMutableSourceForHydration,
      runWithPriority = _reactReconcilerProductionMin.runWithPriority,
      shouldSuspend = _reactReconcilerProductionMin.shouldSuspend,
      unbatchedUpdates = _reactReconcilerProductionMin.unbatchedUpdates,
      updateContainer = _reactReconcilerProductionMin.updateContainer;